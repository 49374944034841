import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import PixIntegradorConfiguracaoCreateComponent from '@/components/PixIntegradorConfiguracaoCreateComponent.vue';
import { companyConfiguracaoIntegracaoBancariaStore, contaBancariaStore, pixStore, } from '@/store';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let PixIntegradorConfiguracaoComponent = class PixIntegradorConfiguracaoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Banco', value: 'banco_id' },
            { text: 'Client ID', value: 'client_id' },
            { text: 'Client Secret', value: 'client_secret' },
            { text: 'Ativa', value: 'is_active' },
            { text: '', value: 'actions' },
        ];
        this.dialogIntegracao = false;
        this.selectedItemId = null;
        this.selectPeriodDate = null;
        this.items = [];
        this.integracaoId = 0;
    }
    async gerarCertificado(item) {
        await companyConfiguracaoIntegracaoBancariaStore.gerarCertificadoCompanyConfiguracaoIntegracaoBancaria(item.id);
    }
    async webhookCadastrar(item) {
        await companyConfiguracaoIntegracaoBancariaStore.cadastrarWebhookCompanyConfiguracaoIntegracaoBancaria(item.id);
    }
    // deixar comentado
    // public async webhookExcluir(item) {
    //   await companyConfiguracaoIntegracaoBancariaStore.excluirWebhookCompanyConfiguracaoIntegracaoBancaria(
    //     item.id,
    //   );
    // }
    async webhookConsultar(item, idx) {
        const res = await companyConfiguracaoIntegracaoBancariaStore.consultarWebhookCompanyConfiguracaoIntegracaoBancaria(item.id);
        if (res.status) {
            this.items[idx].webhookData = {};
        }
        else {
            this.items[idx].webhookData = res;
        }
        this.items = [...this.items];
    }
    async token(item) {
        await companyConfiguracaoIntegracaoBancariaStore.tokenCompanyConfiguracaoIntegracaoBancaria(item.id);
    }
    async sincronizarRecebimentos(item) {
        await pixStore.pixSincronizarRecebimentos({
            company_configuracao_integracao_bancaria_id: item.id,
            data_inicio: this.selectPeriodDate[0],
            data_fim: this.selectPeriodDate[1],
            company_id: this.propCompanyId,
        });
    }
    async get() {
        if (this.propCompanyId) {
            return await companyConfiguracaoIntegracaoBancariaStore.getCompanyConfiguracaoIntegracaoBancarias(this.propCompanyId);
        }
        else {
            return await companyConfiguracaoIntegracaoBancariaStore.getCompanyConfiguracaoIntegracaoBancariasByContaBancariaId(this.propContaBancariaId);
        }
    }
    async onCancel() {
        this.dialogIntegracao = false;
        setTimeout(async () => {
            this.items = await this.get();
        }, 500);
    }
    async handleDelete(item) {
        this.$swal({
            title: 'Confirma a exclusão da integração?',
            text: 'Após exclusão a integração não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await companyConfiguracaoIntegracaoBancariaStore.deleteCompanyConfiguracaoIntegracaoBancaria(item.id);
                this.items = await this.get();
            }
        });
    }
    createIntegracao() {
        this.integracaoId = 0;
        this.dialogIntegracao = true;
    }
    handleEdit(item) {
        this.integracaoId = item.id;
        this.dialogIntegracao = true;
    }
    async mounted() {
        this.items = await this.get();
        await contaBancariaStore.getContasBancarias(true);
    }
};
__decorate([
    Prop({ type: Number, default: null })
], PixIntegradorConfiguracaoComponent.prototype, "propCompanyId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], PixIntegradorConfiguracaoComponent.prototype, "propContaBancariaId", void 0);
PixIntegradorConfiguracaoComponent = __decorate([
    Component({
        components: {
            PixIntegradorConfiguracaoCreateComponent,
            PeriodSelectorComponent,
        },
    })
], PixIntegradorConfiguracaoComponent);
export default PixIntegradorConfiguracaoComponent;
